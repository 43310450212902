@media (min-width: 1000px) {
    .banner{
        width: 10vw;
        height: 100vh;
        /* background-color: cadetblue; */
    }
}
@media (max-width: 999px) {
    .banner{
        width: 100%;
        height: 10vh;
        background-color: cadetblue;
    }
}
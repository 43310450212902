@media (min-width: 1000px) {
    .liveHere {
        border-radius: 3px;
        height: 10px;
        margin-left: 10px;
        width: 10px;
        background-color: darkblue;
    }
}

@media (max-width: 999px) {
    .liveHere {
        border-radius: 3px;
        height: 20px;
        margin-left: 10px;     
        width: 20px;
        background-color: darkblue;
    }
}

.head{
    margin-bottom: 1.5em;
}

.name {
    text-transform: capitalize;
}

.eras {
    width: 80%;
    margin-top: 1em;
    
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    align-content: space-between;
    margin-bottom: 2em;
    border-radius: 10px;
    box-shadow: 
    3px 3px 5px  #adadad, 
    -3px -3px 5px #f7f7f7;
}

.era {
    border: 1px solid;
    height: 3.5em;
    width: 32%;
}

.eraDivider {
    width:33%;
    height: 1em;
    justify-content: space-between;
    
}

.earlyMidLate {
    width: 100%;
    display: flex;
    height: 3em;
}

.eraPicture{
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    width: 80%;
    border-radius: 10px;
    box-shadow: 
    -3px -3px 5px  #adadad, 
    3px 3px 5px #f7f7f7;
}


@media (min-width: 1000px) {
    .main{
        display: flex;
        background-color: #ededed;
        justify-content: space-between;
    }
}

@media (max-width: 999px) {
    .main{
        display: flex;
        background-color: #ededed;
        flex-direction: column;
    }
}
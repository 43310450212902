.dinoCard {
    background: rgb(232,232,232);
    background: linear-gradient(133deg, rgba(232,232,232,1) 0%, rgba(218,218,218,1) 100%);
    color: #99928b;
    width: 75vw;
    margin-top: 10px;
    /* height: 90vh; */
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 
        3px 3px 5px  #adadad, 
        -3px -3px 5px #f7f7f7;
    
}

@media (min-width: 1000px) {
    .dinoCard {
        width: 75vw;
    }
    .phone {
        display: none;
        max-width: 40vw;
    }
}

@media (max-width: 999px) {
    .dinoCard {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 90vw;
        font-size: 2em;
    }

    .desktop{
        display: none;
    }
    .button {
        font-size: larger;
        color: #99928b;
        margin-left: 25%;
    }
}

.geoText {
    width: 50vw;
}

.image {
    max-width: 100%;
    height: auto;
    box-shadow: 
    -3px -3px 5px  #adadad, 
    3px 3px 5px #f7f7f7;
}

.line {
    border: 1px ridge black;
    width: 90%;
    margin-bottom: 1.5em;
}


.button {
    margin-top: 1.5em;
    border: none;
    background: rgb(232,232,232);
    background: linear-gradient(133deg, rgba(232,232,232,1) 0%, rgba(218,218,218,1) 100%);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 
        3px 3px 5px  #adadad, 
        -3px -3px 5px #f7f7f7;
}

.button:active {
    box-shadow: 
        -3px -3px 5px  #adadad, 
        3px 3px 5px #f7f7f7;
}

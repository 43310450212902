.text {
    margin-bottom: 1em;
}

.title {
    font-size: large;
    font-weight: bold;
}

.references {
    font-size: small;
    font-style: italic;
}

table {
    border: 1px solid black;
    width: 100%;
    padding: 5px;
}

table tr:nth-child(even) {
    background-color: #ededed;
}

